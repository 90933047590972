import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../static/rt_logo.png';
import './Navbar.css';

const Navbar = ({
  user,
  clients,
  selectedClient,
  onClientChange,
  locations,
  selectedLocation,
  onLocationChange,
  selectedDistance,
  onDistanceChange,
  onLogout,
}) => {

  const handleLocationSelect = (event) => {
    onLocationChange(event.target.value);
  };

  const handleDistanceSelect = (event) => {
    onDistanceChange(Number(event.target.value));
  };

  const handleClientSelect = (event) => {
    onClientChange(event.target.value);
  };

  return (
    <nav className="navbar navbar-expand-lg bg-light navbar-light">
      <div className="navbar-left" style={{ display: 'flex', alignItems: 'center' }}>
        <Link to="/" className="navbar-brand">
          <img src={logo} className="img-fluid" id="brand-logo" alt="Brand Logo" />
        </Link>

        {/* Location Selector */}
        {user && locations.length > 0 && (
          <div className="selectors" style={{ marginLeft: '20px', display: 'flex', alignItems: 'center' }}>
            <select 
              value={selectedLocation || ''} 
              onChange={handleLocationSelect} 
              className="location-select"
            >
              {locations.map(location => (
                <option key={location.id} value={String(location.id)}>
                  {location.name}
                </option>
              ))}
            </select>

            {/* Distance Selector */}
            <select 
              value={selectedDistance} 
              onChange={handleDistanceSelect} 
              className="distance-select" 
              style={{ marginLeft: '10px' }}
            >
              {[3, 5, 10, 20, 30].map(distance => (
                <option key={distance} value={distance}>
                  {distance} miles
                </option>
              ))}
            </select>
          </div>
        )}
      </div>

      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav ml-auto">
          {user?.isAdmin && (
            <li className="nav-item d-flex align-items-center">
              <label htmlFor="client-select" className="mr-2 mb-0">Client:</label>
              <select 
                id="client-select" 
                value={selectedClient || ''} 
                onChange={handleClientSelect}
                className="client-select"
              >
                <option value="" disabled>Select a client</option>
                {clients.map(client => (
                  <option key={client.id} value={String(client.id)}>
                    {client.name} ({client.email})
                  </option>
                ))}
              </select>
            </li>
          )}
          {user ? (
            <>
              <li className="nav-item">
                <Link className="nav-link" to="/">App Home</Link>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://realtorch.ai" target="_blank" rel="noopener noreferrer">Main Site</a>
              </li>
              <li className="nav-item">
                <button className="btn btn-link nav-link" onClick={onLogout}>Logout</button>
              </li>
            </>
          ) : (
            <>
              <li className="nav-item">
                <a className="nav-link" href="https://realtorch.ai" target="_blank" rel="noopener noreferrer">Main Site</a>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/login">Login</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/sign-up">Sign Up</Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;

import React, { useState, useEffect } from 'react';
import ChartComponent from './ChartComponent';
import './ProductEvaluator.css';  // Import the CSS file
import api from '../api'; // Assuming you have an API utility for fetching data
import { auth } from '../firebase'; // Assuming you have an authentication utility for fetching Firebase ID token

const ProductEvaluator = ({ selectedLocation, selectedDistance, selectedClient }) => {
  const [selectedCategory, setSelectedCategory] = useState('sale'); // 'rental' or 'sale'
  const [selectedPropertyType, setSelectedPropertyType] = useState('apartment'); // Default to apartment
  const [selectedBeds, setSelectedBeds] = useState(null); // Number of beds
  const [charts, setCharts] = useState([]);

  const propertyTypes = {
    sale: {
      townhouse: [2, 3, 4],
      condo: [2, 3],
      single_family: [3, 4, 5]
    },
    rental: {
      apartment: [0, 1, 2, 3],
      townhouse: [2, 3, 4],
      condo: [2, 3],
      single_family: [3, 4, 5]
    }
  };

  useEffect(() => {
    if (selectedBeds !== null && selectedPropertyType && selectedDistance) {
      fetchChartsData(selectedCategory, selectedPropertyType, selectedBeds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, selectedPropertyType, selectedBeds, selectedDistance, selectedLocation]);

  const fetchChartsData = async (category, propertyType, beds) => {
    try {
        // Get current Firebase ID token
        const idToken = await auth.currentUser?.getIdToken();
        if (!idToken) {
            throw new Error('No ID token available');
        }

        const baseChartName = `${category}_${propertyType}_${beds}_bed`;
        const chartNames = [
            `${baseChartName}_supply_and_demand`,
            `${baseChartName}_regression_line`
        ];

        const response = await api.get(`/api/charts`, {
            headers: {
                'Authorization': `Bearer ${idToken}`
            },
            params: {
                locationId: selectedLocation,
                selectedDistance,
                client_id: selectedClient,
                'chartNames': chartNames
            },
            paramsSerializer: params => {
                return Object.entries(params)
                    .map(([key, value]) => Array.isArray(value)
                        ? value.map(v => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`).join('&')
                        : `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
                    .join('&');
            }
        });

        // Sort charts to ensure "supply and demand" appears first
        const sortedCharts = response.data.sort((a, b) => {
            if (a.chart_name.includes('supply_and_demand')) return -1;
            if (b.chart_name.includes('supply_and_demand')) return 1;
            return 0;
        });

        setCharts(sortedCharts);
    } catch (error) {
        console.error('Error fetching charts:', error);
        setCharts([]); // Reset charts on error
    }
};



  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setSelectedPropertyType(Object.keys(propertyTypes[category])[0]); // Select the first property type by default
    setSelectedBeds(null); // Reset bed selection
  };

  const handlePropertyTypeChange = (type) => {
    setSelectedPropertyType(type);
    setSelectedBeds(null); // Reset bed selection
  };

  const handleBedsChange = (beds) => {
    setSelectedBeds(beds);
  };

  return (
    <div>
      <div className="section-buttons">
        <button
          className={selectedCategory === 'sale' ? 'active' : ''}
          onClick={() => handleCategoryChange('sale')}
        >
          For Sale
        </button>
        <button
          className={selectedCategory === 'rental' ? 'active' : ''}
          onClick={() => handleCategoryChange('rental')}
        >
          Rent
        </button>
      </div>

      <div className="product-types-container">
        {Object.keys(propertyTypes[selectedCategory]).map((type) => (
          <div key={type} className="product-type-container">
            <h3>{type.replace('_', ' ').replace(/\b\w/g, char => char.toUpperCase())}</h3>
            <div className="product-type-buttons">
              {propertyTypes[selectedCategory][type].map((beds) => (
                <button
                  key={beds}
                  className={selectedBeds === beds && selectedPropertyType === type ? 'active' : ''}
                  onClick={() => {
                    handlePropertyTypeChange(type);
                    handleBedsChange(beds);
                  }}
                >
                  {beds} Bed
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>

      <div id="charts-container" className="charts-container">
        {charts.length > 0 ? (
          charts.map((chartData, index) => (
            <div key={index} className="chart-wrapper">
              <h2>{chartData.display_name || chartData.chart_name}</h2>
              <ChartComponent chartData={chartData} />
            </div>
          ))
        ) : (
          <p>Please select a product type and number of beds to view the charts.</p>
        )}
      </div>
    </div>
  );
};

export default ProductEvaluator;

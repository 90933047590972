// src/components/Login.js

import React, { useState } from 'react';
import './Login.css';
import { auth } from '../firebase';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import api from '../api'; 
import { useNavigate, Link } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [resetEmail, setResetEmail] = useState('');
  const [showResetModal, setShowResetModal] = useState(false);
  const navigate = useNavigate();

  // Handle traditional email/password login
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // Authenticate with Firebase
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      console.log(userCredential)
      console.log(user)
      // Get Firebase ID token
      const idToken = await user.getIdToken();

      // Send ID token to backend for verification and session management
      const response = await api.post('/firebase-login', { idToken }, { withCredentials: true });

      if (response.data.success) {
        // User is authenticated and session is set
        // Redirect is handled by the Firebase auth listener in App.jsx
        navigate('/');
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Login Error:', error);
      if (error.code === 'auth/user-not-found') {
        alert('No user found with this email.');
      } else if (error.code === 'auth/wrong-password') {
        alert('Incorrect password.');
      } else {
        alert('An error occurred during login. Please try again.');
      }
    }
  };

  // Add this new function to handle password reset
  const handlePasswordReset = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      alert('Password reset email sent! Please check your inbox.');
      setShowResetModal(false);
      setResetEmail('');
    } catch (error) {
      console.error('Password Reset Error:', error);
      if (error.code === 'auth/user-not-found') {
        alert('No user found with this email address.');
      } else if (error.code === 'auth/invalid-email') {
        alert('Please enter a valid email address.');
      } else {
        alert('An error occurred. Please try again.');
      }
    }
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <h3 align="center">Login</h3>
        
        <form onSubmit={handleLogin}>
          <div className="form-group mb-3">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              className="form-control"
              id="password"
              name="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary w-100 mb-3">Login</button>
        </form>
        
        <div className="mt-2 text-center">
          <button 
            onClick={() => setShowResetModal(true)} 
            className="btn btn-link p-0"
          >
            Forgot Password?
          </button>
        </div>
        
        <p className="mt-2 text-center">
          Don't have an account? <Link to="/sign-up">Sign Up</Link>
        </p>
      </div>

      {/* Password Reset Modal */}
      {showResetModal && (
        <div className="modal d-block" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Reset Password</h5>
                <button 
                  type="button" 
                  className="btn-close" 
                  onClick={() => setShowResetModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <form onSubmit={handlePasswordReset}>
                  <div className="form-group">
                    <label htmlFor="resetEmail">Email Address</label>
                    <input
                      type="email"
                      className="form-control"
                      id="resetEmail"
                      value={resetEmail}
                      onChange={(e) => setResetEmail(e.target.value)}
                      placeholder="Enter your email"
                      required
                    />
                  </div>
                  <div className="mt-3">
                    <button type="submit" className="btn btn-primary">
                      Send Reset Link
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary ms-2"
                      onClick={() => setShowResetModal(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;

// src/App.jsx

import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Login from './components/Login';
import SignUp from './components/SignUp';
import MacroEconMap from './components/MacroEconMap';
import AuthCallback from './components/AuthCallback';
import './App.css';
import api from './api'; 
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';

const App = () => {
  const [user, setUser] = useState(null); // State for user authentication
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedDistance, setSelectedDistance] = useState(10); // Default distance
  const [selectedSection, setSelectedSection] = useState('overview');
  const [isLoading, setIsLoading] = useState(true); // Combined loading state
  const navigate = useNavigate();
  const location = useLocation();

  // Listen for authentication state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        console.log("Firebase user detected:", firebaseUser.email);
        try {
          // Get Firebase ID token
          const idToken = await firebaseUser.getIdToken();

          // Send ID token to backend for verification and session creation
          const response = await api.post('/firebase-login', { idToken }, { withCredentials: true });

          if (response.data.success) {
            // Set user data from backend
            setUser({
              id: response.data.user.id,
              email: response.data.user.email,
              name: response.data.user.name,
              isAdmin: response.data.user.is_admin,
            });
            console.log("User authenticated via backend:", response.data);
            // Redirect if on auth callback
            if (location.pathname === '/auth/callback') {
              navigate('/');
            }
          } else {
            console.log("Backend authentication failed:", response.data.message);
            setUser(null);
            // Optionally sign out from Firebase if backend fails
            await auth.signOut();
          }
        } catch (error) {
          console.error("Error during backend authentication:", error);
          setUser(null);
          // Optionally sign out from Firebase on error
          await auth.signOut();
        }
      } else {
        console.log("No Firebase user detected. User is logged out.");
        setUser(null);
      }
      setIsLoading(false); // Authentication check complete
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [navigate, location.pathname]);

  // Fetch clients and locations when user changes
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Start loading
      if (user) {
        if (user.isAdmin) {
          console.log("User is admin. Fetching clients...");
          await fetchClients();
        } else {
          console.log("User is not admin. Fetching user-specific locations...");
          await fetchLocations(user.id); // Fetch locations for non-admin user
        }
      } else {
        console.log("User is not logged in. Clearing client and location states.");
        setClients([]);
        setSelectedClient(null);
        setLocations([]);
        setSelectedLocation(null);
      }
      setIsLoading(false); // Loading complete
    };
    fetchData();
  }, [user]); // eslint-disable-line

  // Function to fetch clients (for admin users)
  const fetchClients = async () => {
    try {
      console.log("Fetching clients...");
      const response = await api.get('/api/clients', { withCredentials: true });
      if (response.status === 200) {
        setClients(response.data);
        console.log('Fetched clients:', response.data);
        // If no client is selected, select the first client by default
        if (response.data.length > 0 && !selectedClient) {
          const firstClientId = String(response.data[0].id); // Ensure it's a string
          setSelectedClient(firstClientId);
          console.log('Selected first client:', firstClientId);
          await fetchLocations(firstClientId); // Fetch locations for the first client
        }
      } else {
        console.log('Failed to fetch clients with status:', response.status);
      }
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  // Function to fetch locations based on client ID (for admin users) or user-specific locations
  const fetchLocations = async (clientId = null) => {
    try {
      console.log(`Fetching locations for clientId: ${clientId}`);
      
      // Get current Firebase ID token
      const idToken = await auth.currentUser?.getIdToken();
      if (!idToken) {
        throw new Error('No ID token available');
      }

      const params = clientId ? { client_id: clientId } : {};
      const response = await api.get('/get-locations', {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        params
      });

      if (response.status === 200) {
        setLocations(response.data);
        console.log(`Fetched locations:`, response.data);

        // Set the default selected location to the first one if not already set
        if (response.data.length > 0 && !selectedLocation) {
          const firstLocationId = String(response.data[0].id); // Ensure it's a string
          setSelectedLocation(firstLocationId);
          console.log('Selected first location:', firstLocationId);
        }
      } else {
        console.log('Failed to fetch locations with status:', response.status);
      }
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  };

  // Handle client change (for admin users)
  const handleClientChange = async (clientId) => {
    console.log(`Changing selected client to: ${clientId}`);
    setSelectedClient(clientId);
    await fetchLocations(clientId); // Fetch locations for the selected client
  };

  // Handle location change
  const handleLocationChange = (locationId) => {
    console.log(`Changing selected location to: ${locationId}`);
    setSelectedLocation(locationId);
  };

  // Handle distance change
  const handleDistanceChange = (distance) => {
    console.log(`Changing selected distance to: ${distance}`);
    setSelectedDistance(distance);
  };

  // Handle section change
  const handleSectionChange = (section) => {
    console.log(`Changing selected section to: ${section}`);
    setSelectedSection(section);
  };

  // Handle logout by clearing all relevant states
  const handleLogout = async () => {
    console.log("Logging out. Clearing all user-related states.");
    try {
      await auth.signOut(); // Sign out from Firebase only
      setUser(null);
      setClients([]);
      setSelectedClient(null);
      setLocations([]);
      setSelectedLocation(null);
      setSelectedDistance(10);
      setSelectedSection('overview');
      navigate('/login'); // Redirect to login after logout
    } catch (error) {
      console.error("Logout failed:", error);
      alert("An error occurred during logout. Please try again.");
    }
  };

  if (isLoading) {
    return (
      <div className="container mt-5 pt-5">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <>
      <Navbar 
        user={user} 
        setUser={setUser}
        clients={clients}
        selectedClient={selectedClient}
        onClientChange={handleClientChange}
        locations={locations}
        selectedLocation={selectedLocation}
        onLocationChange={handleLocationChange}
        selectedDistance={selectedDistance}
        onDistanceChange={handleDistanceChange}
        onLogout={handleLogout} // Pass the logout handler
      />
      <div className="container mt-5 pt-5">
        <Routes>
          <Route 
            path="/" 
            element={
              <Home 
                user={user}
                selectedLocation={selectedLocation}
                selectedDistance={selectedDistance}
                selectedClient={selectedClient}
                selectedSection={selectedSection}
                onSectionChange={handleSectionChange}
              />
            } 
          />
          <Route path="/login" element={<Login />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/macro-econ-index" element={<MacroEconMap />} />
          <Route path="/auth/callback" element={<AuthCallback />} />
        </Routes>
      </div>
    </>
  );
};

export default App;

import React, { useEffect, useState, useCallback, useRef } from 'react';
import ChartComponent from './ChartComponent';
import api from '../api';
import './MarketOverview.css';

const MarketOverview = ({ selectedLocation, locationInfo }) => {
  const [permitData, setPermitData] = useState(null);
  const [listingsData, setListingsData] = useState(null);
  const [selectedListings, setSelectedListings] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null);
  const [activeCell, setActiveCell] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const listingsPerPage = 10; // Maximum of 10 listings per page
  const [populationData, setPopulationData] = useState(null);

  const loadingRef = useRef(false);

  const fetchPermitData = useCallback(async () => {
    if (loadingRef.current || !locationInfo?.fips_code) return;

    loadingRef.current = true;
    try {
      const response = await api.get('/get-county-permit-table-and-info', {
        params: { 'county-fips': locationInfo.fips_code },
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });

      setPermitData(response.data);
    } catch (error) {
      console.error('Error fetching permit data:', error);
      setPermitData(null);
    } finally {
      loadingRef.current = false;
    }
  }, [locationInfo]);

  const fetchListingsData = useCallback(async () => {
    if (!locationInfo?.fips_code || !locationInfo?.latitude || !locationInfo?.longitude) return;
    try {
      const response = await api.get('/get-listings-updates', {
        params: {
          'location-id': selectedLocation,
          'county-fips': locationInfo.fips_code,
          'latitude': locationInfo.latitude,
          'longitude': locationInfo.longitude,
          'last_n_days': 7,
          'last_n_years': 2,
          'property_type': 'Single Family'
        },
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });

      setListingsData(response.data);
    } catch (error) {
      console.error('Error fetching listings data:', error);
      setListingsData(null);
    }
  }, [locationInfo, selectedLocation]);

  const fetchPopulationData = useCallback(async () => {
    if (!locationInfo?.fips_code) return;
    try {
      const response = await api.get('/get-population-growth', {
        params: { 'county-fips': locationInfo.fips_code },
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      setPopulationData(response.data);
    } catch (error) {
      console.error('Error fetching population data:', error);
      setPopulationData(null);
    }
  }, [locationInfo]);

  useEffect(() => {
    fetchPermitData();
  }, [fetchPermitData]);

  useEffect(() => {
    fetchListingsData();
  }, [fetchListingsData]);

  useEffect(() => {
    fetchPopulationData();
  }, [fetchPopulationData]);

  if (!locationInfo) {
    return <div className="overview-container">Loading location information...</div>;
  }

  const handleSummaryCellClick = (index, distanceCategory, listingType) => {
    const distancePrefix = distanceCategory === 'Within 2 miles' ? '2_miles' : '5_miles';
    let listingKeyPart = '';

    if (listingType === 'New Listings') {
      listingKeyPart = 'new_listings';
    } else if (listingType === 'Active Listings') {
      listingKeyPart = 'active_listings';
    } else if (listingType === 'Removed Listings') {
      listingKeyPart = 'removed_listings';
    }

    const detailedKey = `${distancePrefix}_${listingKeyPart}`;

    const newSelectedListings = (listingsData && listingsData.detailed_listings && listingsData.detailed_listings[detailedKey]) 
      ? listingsData.detailed_listings[detailedKey] 
      : [];

    // If the same row is clicked again, toggle off
    if (expandedRow === index) {
        setExpandedRow(null);
        setSelectedListings(null);
        setActiveCell(null);
      } else {
        setExpandedRow(index);
        setSelectedListings(newSelectedListings);
        setCurrentPage(0);
        // Mark this cell as active
        setActiveCell({ rowIndex: index, listingType: listingType });
      }
  };

  // Pagination logic
  const totalListings = selectedListings ? selectedListings.length : 0;
  const totalPages = Math.ceil(totalListings / listingsPerPage);
  const startIndex = currentPage * listingsPerPage;
  const endIndex = startIndex + listingsPerPage;
  const currentListings = selectedListings ? selectedListings.slice(startIndex, endIndex) : [];

  return (
    <div className="overview-wrapper">
      <div className="overview-container">
        <h4>
          {locationInfo.city_name}, {locationInfo.state_abbreviation} is located in Zip Code{' '}
          {locationInfo.zip_code.toString().padStart(5, '0')} and is in {locationInfo.county_name} County
        </h4>
        {populationData && populationData.data && populationData.data.length >= 2 && (
          <h5>
            {locationInfo.county_name} County's population grew{' '}
            <strong style={{color: 'black'}}>{populationData.growth_rates['1_year_growth']?.toFixed(2)}%</strong> to{' '}
            <strong style={{color: 'black'}}>{populationData.recent_month_value?.toLocaleString()}</strong> from{' '}
            <strong style={{color: 'black'}}>{populationData.data[1].resident_population?.toLocaleString()}</strong> for the year{' '}
            <strong style={{color: 'black'}}>{populationData.data[0].year}</strong>. 
            It grew <strong style={{color: 'black'}}>{populationData.growth_rates['3_year_growth']?.toFixed(2)}%</strong> over the past 3 years
            {populationData.growth_rates['10_year_growth'] !== null && (
              <> and <strong style={{color: 'black'}}>{populationData.growth_rates['10_year_growth']?.toFixed(2)}%</strong> over the past 10 years</>
            )}.
          </h5>
        )}
      </div>
      <div className="charts-row">
        <div className="overview-container">
          {permitData?.latest_data_date ? (
            <h4>Building Permits for {locationInfo.county_name} County, {locationInfo.state_abbreviation} as of {permitData.latest_data_date}:</h4>
          ) : (
            <h4>Building Permits for {locationInfo.county_name} County, {locationInfo.state_abbreviation}</h4>
          )}
          
          <div className="permit-table">
            {permitData?.headers && permitData?.rows ? (
              <table className="styled-table">
                <thead>
                  <tr>
                    {permitData.headers.map((header, index) => (
                      <th key={index}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {permitData.rows.map((row, index) => (
                    <tr key={index}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex}>{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>Loading permit data...</p>
            )}
          </div>
        </div>

        {permitData?.single_family_permits_chart_js_output && (
          <div className="overview-container">
            <h4>Single Family Home Permits for {locationInfo.county_name} County, {locationInfo.state_abbreviation}</h4>
            <div className="permit-chart">
              <ChartComponent 
                chartData={{ 
                  chart_js_json: permitData.single_family_permits_chart_js_output 
                }} 
              />
            </div>
          </div>
        )}
      </div>

      <div className="overview-container">
        <h4>Listings Updates as of {listingsData?.time_stamp}</h4>
        {listingsData?.summary_statistics ? (
          <table className="styled-table">
            <thead>
              <tr>
                <th>Distance</th>
                <th>New listings</th>
                <th>Active Listings</th>
                <th>Removed Listings</th>
              </tr>
            </thead>
            <tbody>
            {Object.entries(listingsData.summary_statistics).map(([distCategory, values], index) => (
                <React.Fragment key={index}>
                    <tr>
                    <td>{distCategory}</td>
                    <td 
                        className={`clickable-cell ${activeCell && activeCell.rowIndex === index && activeCell.listingType === 'New Listings' ? 'active-cell' : ''}`}
                        onClick={() => handleSummaryCellClick(index, distCategory, 'New Listings')}
                    >
                        {values['New Listings']}
                    </td>
                    <td 
                        className={`clickable-cell ${activeCell && activeCell.rowIndex === index && activeCell.listingType === 'Active Listings' ? 'active-cell' : ''}`}
                        onClick={() => handleSummaryCellClick(index, distCategory, 'Active Listings')}
                    >
                        {values['Active Listings']}
                    </td>
                    <td 
                        className={`clickable-cell ${activeCell && activeCell.rowIndex === index && activeCell.listingType === 'Removed Listings' ? 'active-cell' : ''}`}
                        onClick={() => handleSummaryCellClick(index, distCategory, 'Removed Listings')}
                    >
                        {values['Removed Listings']}
                    </td>
                    </tr>
                  {expandedRow === index && selectedListings && (
                    <tr className="details-row">
                      <td colSpan={4}>
                        <div className="expanded-details" style={{ background: '#f0f0f0', padding: '10px', border: '1px solid #ccc', marginTop: '10px' }}>
                          <h4>Detailed Listings</h4>
                          {currentListings.length > 0 ? (
                            <React.Fragment>
                              <table className="styled-table">
                                <thead>
                                  <tr>
                                    <th>Address</th>
                                    <th>Bedrooms</th>
                                    <th>Price</th>
                                    <th>Year Built</th>
                                    <th>Square Footage</th>
                                    <th>Days on Market</th>
                                    <th>Status</th>
                                    <th>Zillow</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {currentListings.map((listing, idx) => (
                                    <tr key={idx}>
                                      <td>{listing.formatted_address}</td>
                                      <td>{listing.bedrooms}</td>
                                      <td>{listing.price}</td>
                                      <td>{listing.year_built}</td>
                                      <td>{listing.square_footage}</td>
                                      <td>{listing.days_on_market}</td>
                                      <td>{listing.status}</td>
                                      <td>
                                        <a
                                          href={listing.Zillow}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{ textDecoration: 'none' }}
                                        >
                                          <button style={{ padding: '5px 10px', background: '#0077cc', color: 'white', border: 'none', borderRadius: '5px' }}>
                                            View on Zillow
                                          </button>
                                        </a>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>

                              {/* Pagination Controls */}
                              {totalPages > 1 && (
                                <div style={{display:'flex', justifyContent:'space-between', marginTop:'10px'}}>
                                  <button 
                                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 0))} 
                                    disabled={currentPage === 0}
                                  >
                                    Previous
                                  </button>
                                  <span>Page {currentPage + 1} of {totalPages}</span>
                                  <button 
                                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages - 1))} 
                                    disabled={currentPage === totalPages - 1}
                                  >
                                    Next
                                  </button>
                                </div>
                              )}
                            </React.Fragment>
                          ) : (
                            <p>No listings found for this category.</p>
                          )}
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        ) : (
          <p>Loading listings summary...</p>
        )}
      </div>
    </div>
  );
};

export default MarketOverview;

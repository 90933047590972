import React, { useState, useEffect, useRef, useCallback } from 'react';
import mapboxgl from 'mapbox-gl';
import './ClusteredListings.css';
import api from '../api';
import DataTable from 'react-data-table-component';
import Chart from 'chart.js/auto';

// Use environment variable for Mapbox token for security
mapboxgl.accessToken = 'pk.eyJ1IjoibWljaGFlbC1ydCIsImEiOiJjbTFmY2gxZGYydjBhMmptejRkMXY3NnE4In0.HLgD2rXqws3hQ0Wr7X7PQw';

// Move clusterColors outside the component
const clusterColors = [
  'red', 'blue', 'green', 'orange', 'purple',
  'yellow', 'pink', 'brown', 'cyan', 'magenta',
  'lime', 'indigo', 'teal', 'violet', 'gold',
];

const ClusteredListings = ({ selectedLocation, selectedDistance, selectedClient }) => {
  // State declarations
  const [selectedCategory, setSelectedCategory] = useState('sale');
  const [selectedPropertyType, setSelectedPropertyType] = useState('Single Family');
  const [selectedBeds, setSelectedBeds] = useState([]);
  const [selectedCluster, setSelectedCluster] = useState(5);
  const [clusterData, setClusterData] = useState([]);
  const [mapMarkers, setMapMarkers] = useState([]);
  const [transformedListings, setTransformedListings] = useState([]);
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const [mapboxUrl, setMapboxUrl] = useState(null);
  const [mapboxLayer, setMapboxLayer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});
  const [isUpdatingMarkers, setIsUpdatingMarkers] = useState(false);

  // Refs
  const popupRef = useRef(new mapboxgl.Popup({ closeButton: false, closeOnClick: false }));
  const mapRef = useRef(null);
  const communityMarkerRef = useRef(null);

  // Step 1: Define base functions first
  const getClusterColor = useCallback((cluster, status) => {
    return clusterColors[cluster - 1] || 'gray';
  }, []);

  // Step 2: Define clearMapMarkers
  const clearMapMarkers = useCallback(() => {
    mapMarkers.forEach(marker => marker.remove());
    setMapMarkers([]);
  }, [mapMarkers]);

  // Step 3: Define transformListingsData
  const transformListingsData = useCallback((listings) => {
    return listings
      .map(listing => {
        if (!listing.latitude || !listing.longitude) return null;
        return {
          listingId: listing.id || 'N/A',
          latitude: listing.latitude,
          longitude: listing.longitude,
          address: listing.formatted_address || listing.address || 'N/A',
          bedrooms: listing.bedrooms || 'N/A',
          bathrooms: listing.bathrooms || 'N/A',
          cluster: listing.cluster || 'N/A',
          price: listing.price || 'N/A',
          year_built: listing.year_built || 'N/A',
          lot_size_sq_ft: listing.lot_size_sq_ft || 'N/A',
          days_on_market: listing.days_on_market || 'N/A',
          status: listing.status || 'N/A'
        };
      })
      .filter(Boolean);
  }, []);

  // Step 4: Define createMarker function separately
  const createMarker = useCallback((listing, mapInstance) => {
    if (!listing.longitude || !listing.latitude) return null;

    // Log the transformed listing data to debug
    // console.log('Creating marker with listing:', listing);

    const el = document.createElement('div');
    el.className = 'marker';
    el.style.backgroundColor = getClusterColor(listing.cluster, listing.status);
    el.style.width = '20px';
    el.style.height = '20px';
    el.style.borderRadius = '50%';
    el.style.border = '2px solid white';
    // Store both status and cluster information
    el.dataset.status = listing.status;
    el.dataset.cluster = listing.cluster;

    // Format price with commas
    const formattedPrice = typeof listing.price === 'number' 
      ? listing.price.toLocaleString('en-US', { 
          style: 'currency', 
          currency: 'USD',
          maximumFractionDigits: 0
        })
      : 'N/A';

    // Create popup content with error handling
    const popupContent = `
      <div style="padding: 8px;">
        <div style="font-weight: bold; margin-bottom: 4px;">
          Cluster ${listing.cluster || 'N/A'}
        </div>
        <div style="margin-bottom: 4px;">
          <strong>Address:</strong> ${listing.address !== 'N/A' ? listing.address : 'Address not available'}
        </div>
        <div>
          <strong>Price:</strong> ${formattedPrice}
        </div>
      </div>
    `;

    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      className: 'marker-popup'
    }).setHTML(popupContent);

    const marker = new mapboxgl.Marker(el)
      .setLngLat([listing.longitude, listing.latitude])
      .setPopup(popup);

    if (mapInstance.loaded()) {
      marker.addTo(mapInstance);
    }

    el.addEventListener('mouseenter', () => popup.addTo(mapInstance));
    el.addEventListener('mouseleave', () => popup.remove());

    return marker;
  }, [getClusterColor]);

  // Step 5: Define addMarkersToMap with all dependencies
  const addMarkersToMap = useCallback((listingsData, mapInstance) => {
    if (!mapInstance || !mapInstance.loaded() || isUpdatingMarkers) {
      console.log('Map not ready or markers already updating');
      return;
    }

    try {
      setIsUpdatingMarkers(true);
      clearMapMarkers();
      
      // Process listings in chunks to prevent UI blocking
      const chunkSize = 50;
      const chunks = [];
      for (let i = 0; i < listingsData.length; i += chunkSize) {
        chunks.push(listingsData.slice(i, i + chunkSize));
      }

      let processedMarkers = [];
      let currentChunk = 0;

      const processNextChunk = () => {
        if (currentChunk >= chunks.length) {
          setMapMarkers(processedMarkers);
          if (processedMarkers.length > 0) {
            const bounds = new mapboxgl.LngLatBounds();
            processedMarkers.forEach(marker => {
              bounds.extend(marker.getLngLat());
            });
            mapInstance.fitBounds(bounds, { padding: 50 });
          }
          setIsUpdatingMarkers(false);
          return;
        }

        const chunk = chunks[currentChunk];
        const newMarkers = chunk
          .map(listing => createMarker(listing, mapInstance))
          .filter(Boolean);

        processedMarkers = [...processedMarkers, ...newMarkers];
        currentChunk++;
        requestAnimationFrame(processNextChunk);
      };

      processNextChunk();
    } catch (err) {
      console.error('Error in addMarkersToMap:', err);
      setIsUpdatingMarkers(false);
    }
  }, [clearMapMarkers, createMarker, isUpdatingMarkers]);

  // Property types configuration
  const propertyTypes = {
    sale: {
      'Townhouse': [2, 3, 4],
      'Condo': [2, 3],
      'Single Family': [3, 4, 5]
    },
    rental: {
      'Apartment': [0, 1, 2, 3],
      'Townhouse': [2, 3, 4],
      'Condo': [2, 3],
      'Single Family': [3, 4, 5]
    }
  };

  // Color configuration based on jobs_index
  const getColorByJobsIndex = [
    'case',
    ['==', ['coalesce', ['get', 'jobs_index'], 'NA'], 'NA'],  // Handle cases where 'jobs_index' is 'NA'
    '#cccccc',  // Color for 'NA' values
    [
      'interpolate',
      ['linear'],
      ['to-number', ['coalesce', ['get', 'jobs_index'], 0]],  // Ensure jobs_index is a number, fallback to 0
      0, '#ffffcc',  // Color for jobs_index = 0
      0.2, '#c7e9b4', // Color for jobs_index = 0.2
      0.4, '#7fcdbb', // Color for jobs_index = 0.4
      0.6, '#41b6c4', // Color for jobs_index = 0.6
      0.8, '#1d91c0', // Color for jobs_index = 0.8
      1, '#225ea8' // Color for jobs_index = 1
    ],
  ];

  // Fetch location data when selectedLocation changes
  useEffect(() => {
    const fetchLocationData = async () => {
      if (!selectedLocation) return;

      try {
        // Fetch location coordinates
        const coordinatesResponse = await api.get('/api/get-location-details', {
          params: { locationId: selectedLocation },
        });
        setSelectedCoordinates(coordinatesResponse.data);

        // Fetch Mapbox information
        const mapboxResponse = await api.get('/api/get-mapbox-info-from-location', {
          params: { locationId: selectedLocation },
        });

        // Log the entire response data for debugging
        console.log('Mapbox Response Data:', mapboxResponse.data);

        // Check if mapbox_url and mapbox_layer are returned
        if (mapboxResponse.data && mapboxResponse.data.mapbox_url && mapboxResponse.data.mapbox_layer) {
          setMapboxUrl(mapboxResponse.data.mapbox_url);
          setMapboxLayer(mapboxResponse.data.mapbox_layer);
          console.log('Mapbox URL:', mapboxResponse.data.mapbox_url);
          console.log('Mapbox Layer:', mapboxResponse.data.mapbox_layer);
        } else {
          // If no Mapbox data is returned, set them to null
          setMapboxUrl(null);
          setMapboxLayer(null);
          console.log('No Mapbox data returned for this location.');
        }
      } catch (error) {
        console.error('Error fetching location or Mapbox data:', error);
        setError('Failed to fetch location or Mapbox data.');
        setMapboxUrl(null);
        setMapboxLayer(null);
      }
    };

    fetchLocationData();
  }, [selectedLocation]);

  // Initialize the map when selectedCoordinates change
  useEffect(() => {
    if (selectedCoordinates && !mapRef.current) {
      initializeMap();
    } else if (selectedCoordinates && mapRef.current) {
      mapRef.current.setCenter([selectedCoordinates.longitude, selectedCoordinates.latitude]);
      console.log('Map center updated to:', [selectedCoordinates.longitude, selectedCoordinates.latitude]);
      addCommunityMarker(mapRef.current, selectedCoordinates); // Re-add marker at new location
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCoordinates]);

  // Update the Mapbox layer when mapboxUrl or mapboxLayer changes
  useEffect(() => {
    if (mapboxUrl && mapboxLayer) {
      addOrUpdateMapboxLayer(mapboxUrl, mapboxLayer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapboxUrl, mapboxLayer]);

  // Function to initialize Mapbox map
  const initializeMap = () => {
    console.log('Initializing Mapbox map...');
    const mapContainer = document.getElementById('map');
    if (mapContainer) {
      mapContainer.innerHTML = ''; // Clear previous map content if re-initializing
    }

    const mapInstance = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/light-v11', // Default style
      center: [selectedCoordinates.longitude, selectedCoordinates.latitude],
      zoom: 14,
      antialias: true,
    });

    mapInstance.on('load', () => {
      console.log('Map loaded.');
      addCommunityMarker(mapInstance, selectedCoordinates); // Only call it once here
      if (transformedListings.length > 0) {
        addMarkersToMap(transformedListings, mapInstance);
      }
    });

    mapInstance.on('error', (e) => {
      console.error('Mapbox GL JS Error:', e.error);
    });

    mapRef.current = mapInstance; // Store the map instance in the ref
  };

  // Function to add or update the Mapbox layer
  const addOrUpdateMapboxLayer = (url, layer) => {
    const map = mapRef.current;

    if (!map) {
      console.error('Map instance is not initialized.');
      return;
    }

    // Remove existing layers and source
    ['census-layer', 'census-layer-zoomed-out', 'census-outline'].forEach(layerId => {
      if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
      }
    });

    if (map.getSource('census')) {
      map.removeSource('census');
    }

    try {
      // Add source
      map.addSource('census', {
        type: 'vector',
        url: `mapbox://${url}`,
        promoteId: 'jobs_index'
      });

      // Layer for zoom levels 9-14 (detailed view after clustering stops)
      map.addLayer({
        id: 'census-layer',
        type: 'fill',
        source: 'census',
        'source-layer': layer,
        minzoom: 9,
        maxzoom: 14,
        paint: {
          'fill-color': getColorByJobsIndex,
          'fill-opacity': 0.7,
          'fill-outline-color': '#000000',
        }
      });

      // Layer for zoom levels 5-9 (clustered view)
      map.addLayer({
        id: 'census-layer-zoomed-out',
        type: 'fill',
        source: 'census',
        'source-layer': layer,
        minzoom: 5,
        maxzoom: 9,
        paint: {
          'fill-color': getColorByJobsIndex,
          'fill-opacity': 0.7,
          'fill-outline-color': '#000000',
        }
      });

      // Add outlines for better visibility at lower zoom levels
      map.addLayer({
        id: 'census-outline',
        type: 'line',
        source: 'census',
        'source-layer': layer,
        minzoom: 5,
        maxzoom: 14,
        paint: {
          'line-color': '#000000',
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            5, 0.5,  // thinner lines at low zoom
            14, 1    // thicker lines at high zoom
          ],
          'line-opacity': 0.5
        }
      });

    } catch (error) {
      console.error('Error adding Mapbox source or layer:', error);
    }
  };

  // Function to format Zillow URLs
  const formatZillowURL = (address) => {
    const formattedAddress = address
      .replace(/\s+/g, '-')
      .replace(/,/g, '')
      .replace(/--+/g, '-');

    return `https://www.zillow.com/homes/${formattedAddress}`;
  };

  // DataTable columns configuration for Listings Data
  const columns = [
    {
      name: 'Cluster',
      selector: row => row.cluster,
      sortable: true,
      width: '100px', // Adjust width for the Cluster column
      cell: row => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              width: '15px',
              height: '15px',
              borderRadius: '50%',
              backgroundColor: getClusterColor(row.cluster),
              marginRight: '10px'
            }}
          />
          {row.cluster}
        </div>
      )
    },
    {
      name: 'Address',
      selector: row => row.address,
      sortable: true,
      width: '350px', // Set a minimum width, and allow the column to grow
    },
    {
      name: 'Bedrooms',
      selector: row => row.bedrooms,
      sortable: true,
      width: '110px', // Set a width for this column
    },
    {
      name: 'Bathrooms',
      selector: row => row.bathrooms,
      sortable: true,
      width: '110px', // Set a width for this column
    },
    {
      name: 'Price',
      selector: row => row.price,
      sortable: true,
      cell: row => (
        row.price
          ? `$${formatNumberWithK(row.price)}`
          : 'N/A'
      ),
      width: '120px',
    },
    {
      name: 'Year Built',
      selector: row => row.year_built,
      sortable: true,
      width: '120px', // Set a width for the Year Built column
    },
    {
      name: 'Days On Market',
      selector: row => row.days_on_market,
      sortable: true,
      width: '140px', // Set a width for the Year Built column
    },
    {
      name: 'Lot Size - Sq Ft',
      selector: row => row.lot_size_sq_ft,
      sortable: true,
      width: '150px', // Set a width for the Lot Size column
    },
    {
      name: 'Status',
      selector: row => row.status,
      sortable: true,
      width: '100px',
      cell: row => (
        <div style={{
          padding: '5px 10px',
          borderRadius: '4px',
          backgroundColor: row.status === 'Active' ? '#28a745' : '#dc3545',
          color: 'white',
          textAlign: 'center'
        }}>
          {row.status}
        </div>
      )
    },
    {
      name: 'Zillow Link',
      cell: row => (
        <a
          href={formatZillowURL(row.address)}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <button style={{ padding: '5px 10px', background: '#0077cc', color: 'white', border: 'none', borderRadius: '5px' }}>
            View on Zillow
          </button>
        </a>
      ),
      width: '150px', // Set a width for the Zillow Link column
    }
  ];


// Define the cluster columns with formatted numeric values
// Helper function to format numbers with 'k' suffix when needed
const formatNumberWithK = (num) => {
  if (num >= 1000) {
    // Divide by 1000 and append 'k', ensuring no decimal places
    return `${Math.round(num / 1000)}k`;
  }
  // Return the number as an integer string with comma separators
  return Math.round(num).toLocaleString();
};

// Helper function to format numbers as integers without 'k'
const formatNumberInt = (num) => {
  return Math.round(num).toLocaleString();
};

// Define the cluster columns with appropriate formatted numeric values
const clusterColumns = [
  {
    name: '',
    width: '50px',
    cell: row => (
      <button
        onClick={() => handleRowExpand(row)}
        style={{
          background: 'none',
          border: 'none',
          cursor: 'pointer'
        }}
      >
        {row.isExpanded ? '▼' : '▶'}
      </button>
    ),
    ignoreRowClick: true,
  },
  {
    name: 'Cluster',
    maxWidth: '60px',
    selector: row => row.cluster,
    sortable: true,
    cell: row => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            width: '15px',
            height: '15px',
            borderRadius: '50%',
            backgroundColor: getClusterColor(row.cluster),
            marginRight: '10px'
          }}
        />
        {row.cluster}
      </div>
    )
  },
  {
    name: 'Count',
    maxWidth: '50px',
    selector: row => row.count,
    sortable: true,
    cell: row => (
      row.count !== null && row.count !== undefined
        ? formatNumberWithK(row.count)
        : 'N/A'
    )
  },
  {
    name: 'Avg Price',
    selector: row => row.avgPrice,
    sortable: true,
    cell: row => (
      row.avgPrice
        ? `$${formatNumberWithK(row.avgPrice)}`
        : 'N/A'
    )
  },
  {
    name: 'Price Std Dev',
    selector: row => row.priceStdDev,
    sortable: true,
    cell: row => (
      row.priceStdDev
        ? `$${formatNumberWithK(row.priceStdDev)}`
        : 'N/A'
    )
  },
  {
    name: 'Avg Sq Ft',
    selector: row => row.avgSquareFootage,
    sortable: true,
    cell: row => (
      row.avgSquareFootage
        ? formatNumberInt(row.avgSquareFootage)
        : 'N/A'
    )
  },
  {
    name: 'Sq Ft Std Dev',
    selector: row => row.squareFootageStdDev,
    sortable: true,
    cell: row => (
      row.squareFootageStdDev
        ? formatNumberInt(row.squareFootageStdDev)
        : 'N/A'
    )
  },
  {
    name: 'Avg $ per Sq Ft',
    selector: row => row.avgPricePerSqFt,
    sortable: true,
    cell: row => (
      row.avgPricePerSqFt
        ? `$${formatNumberInt(row.avgPricePerSqFt)}`
        : 'N/A'
    )
  }
];


// Add handler for row expansion
const handleRowExpand = (row) => {
  setExpandedRows(prev => ({
    ...prev,
    [row.cluster]: !prev[row.cluster]
  }));
};

// ExpandedRow component with proper data handling
const ExpandedRow = ({ data }) => {
  const chartRef1 = useRef(null);
  const chartRef2 = useRef(null);
  const chartInstance1 = useRef(null);
  const chartInstance2 = useRef(null);

  useEffect(() => {
    // Cleanup previous charts
    if (chartInstance1.current) {
      chartInstance1.current.destroy();
    }
    if (chartInstance2.current) {
      chartInstance2.current.destroy();
    }

    // Create Price vs Square Footage chart
    if (chartRef1.current && data.price_to_sq_ft_scatter) {
      const ctx1 = chartRef1.current.getContext('2d');
      chartInstance1.current = new Chart(ctx1, {
        type: data.price_to_sq_ft_scatter.type,
        data: data.price_to_sq_ft_scatter.data,
        options: {
          ...data.price_to_sq_ft_scatter.options,
          maintainAspectRatio: false
        }
      });
    }

    // Create Price vs Days on Market chart
    if (chartRef2.current && data.price_to_days_scatter) {
      const ctx2 = chartRef2.current.getContext('2d');
      chartInstance2.current = new Chart(ctx2, {
        type: data.price_to_days_scatter.type,
        data: data.price_to_days_scatter.data,
        options: {
          ...data.price_to_days_scatter.options,
          maintainAspectRatio: false
        }
      });
    }

    // Cleanup function
    return () => {
      if (chartInstance1.current) {
        chartInstance1.current.destroy();
      }
      if (chartInstance2.current) {
        chartInstance2.current.destroy();
      }
    };
  }, [data]);

  return (
    <div style={{ 
      padding: '20px',
      display: 'flex',
      justifyContent: 'space-around',
      backgroundColor: '#f8f9fa',
      gap: '20px'
    }}>
      <div style={{ 
        width: '45%', 
        height: '300px',
        backgroundColor: 'white',
        padding: '15px',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
      }}>
        <canvas ref={chartRef1} style={{ width: '100%', height: '100%' }}></canvas>
      </div>
      <div style={{ 
        width: '45%', 
        height: '300px',
        backgroundColor: 'white',
        padding: '15px',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
      }}>
        <canvas ref={chartRef2} style={{ width: '100%', height: '100%' }}></canvas>
      </div>
    </div>
  );
};

// Modify the DataTable component to pass the correct data
<DataTable
  title="Cluster Summary"
  columns={clusterColumns}
  data={clusterData.map(row => ({
    ...row,
    isExpanded: !!expandedRows[row.cluster]
  }))}
  expandableRows
  expandableRowsComponent={({ data }) => <ExpandedRow data={data} />}
  expandableRowExpanded={row => !!expandedRows[row.cluster]}
  onRowExpandToggled={(expanded, row) => handleRowExpand(row)}
  pagination
  noDataComponent="No listings for this filter"
  customStyles={{
    rows: {
      style: {
        borderBottom: '1px solid #e0e0e0',
      },
    },
    headCells: {
      style: {
        borderBottom: '2px solid #e0e0e0',
        fontWeight: 'bold',
      },
    },
    expanderRow: {
      style: {
        backgroundColor: '#f8f9fa',
      },
    },
  }}
/>

  
  

  // Handle category change (sale/rental)
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    const initialPropertyType = Object.keys(propertyTypes[category])[0];
    setSelectedPropertyType(initialPropertyType);
    setSelectedBeds([]);
  };
  
  // Handle property type change
  const handlePropertyTypeChange = (type) => {
    setSelectedPropertyType(type);
    setSelectedBeds([]);
  };

  // Handle beds selection change
  const handleBedsChange = (beds) => {
    setSelectedBeds((prevSelectedBeds) => {
      if (prevSelectedBeds.includes(beds)) {
        // Remove the bed count from the array
        return prevSelectedBeds.filter((b) => b !== beds);
      } else {
        // Add the bed count to the array
        return [...prevSelectedBeds, beds];
      }
    });
  };

  // Handle cluster count change
  const handleClusterChange = (clusterCount) => {
    setSelectedCluster(clusterCount);
  };

  // Handle Search button click
  const handleSearch = () => {
    if (!selectedCoordinates) {
      setError('Location data is missing.');
      return;
    }
    if (!mapRef.current) {
      setError('Map is not initialized.');
      return;
    }
    setError(null);
    fetchChartsData();
  };

  // Function to fetch cluster and listing data
  const fetchChartsData = async () => {
    setIsLoading(true);
    setError(null);

    try {
      // Check if map is initialized and ready
      if (!mapRef.current || !mapRef.current.loaded()) {
        throw new Error('Map is not ready');
      }

      const response = await api.get(`/api/get_cluster_data`, {
        params: {
          locationId: selectedLocation,
          clusters: selectedCluster,
          searchDistance: selectedDistance,
          listingType: selectedCategory,
          productType: selectedPropertyType,
          beds: selectedBeds,
          latitude: selectedCoordinates.latitude,
          longitude: selectedCoordinates.longitude,
          yearsOld: 3
        },
        paramsSerializer: params => {
          return Object.entries(params)
            .map(([key, value]) =>
              Array.isArray(value)
                ? value.map(v => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`).join('&')
                : `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
            )
            .join('&');
        }
      });
  
      console.log('Response data:', response.data);
      console.log('Type of response data:', typeof response.data);
  
      const { listings_table, cluster_summary } = response.data;
  
      console.log('Listings Table:', listings_table);
      console.log('Cluster Summary:', cluster_summary);
  
      if (listings_table && listings_table.length > 0) {
        const transformedListingsData = transformListingsData(listings_table);
        setTransformedListings(transformedListingsData);
        if (mapRef.current && mapRef.current.loaded()) {
          addMarkersToMap(transformedListingsData, mapRef.current);
        } else {
          console.warn('Map not ready for markers');
        }
      } else {
        setTransformedListings([]);
        clearMapMarkers();
      }
  
      if (cluster_summary && cluster_summary.length > 0) {
        const transformedClusterData = transformClusterData(cluster_summary);
        setClusterData(transformedClusterData);
      } else {
        setClusterData([]);
      }
  
    } catch (error) {
      console.error('Error fetching cluster data:', error);
      setError('Failed to fetch cluster data: ' + error.message);
      setTransformedListings([]);
      setClusterData([]);
      clearMapMarkers();
    } finally {
      setIsLoading(false);
    }
  };

  // Function to transform cluster data (defined only once)
  const transformClusterData = (clusters) => {
    return clusters.map(cluster => {
      return {
        cluster: cluster.Cluster ?? 'N/A',
        avgPrice: cluster['Avg Price'] ?? null,
        priceStdDev: cluster['Price Std Dev'] ?? null,
        avgSquareFootage: cluster['Avg Square Footage'] ?? null,
        squareFootageStdDev: cluster['Square Footage Std Dev'] ?? null,
        count: cluster['Count'] ?? 'N/A',
        avgPricePerSqFt: cluster['Avg Price per Sq Ft'] ?? null,
        price_to_sq_ft_scatter: cluster.price_to_sq_ft_scatter ?? null,
        price_to_days_scatter: cluster.price_to_days_scatter ?? null,
      };
    });
  };

  // Cleanup map and popups on unmount
  useEffect(() => {
    const popup = popupRef.current;

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        console.log('Map instance removed.');
      }
      if (popup) {
        popup.remove();
        console.log('Popup removed.');
      }
      if (communityMarkerRef.current) {
        communityMarkerRef.current.remove();
        console.log('Community marker removed.');
      }
    };
  }, []);

  // Step 4: Update the show/hide inactive toggle
  const handleInactiveToggle = useCallback(() => {
    if (isUpdatingMarkers) return;
    
    const button = document.querySelector('.inactive-toggle-button');
    const isShowingColors = button.getAttribute('data-showing-colors') === 'true';
    
    const markers = document.querySelectorAll('.marker');
    markers.forEach(marker => {
      if (marker.dataset.status === 'Inactive') {
        marker.style.backgroundColor = isShowingColors ? '#808080' : clusterColors[marker.dataset.cluster - 1];
      }
    });
    
    button.setAttribute('data-showing-colors', (!isShowingColors).toString());
    button.textContent = isShowingColors ? 'Show Inactive' : 'Hide Inactive';
  }, [isUpdatingMarkers]);

  // Function to add a marker for the user's community
  const addCommunityMarker = useCallback((mapInstance, coordinates) => {
    if (!coordinates || !mapInstance) return;
  
    // Remove existing community marker if it exists
    if (communityMarkerRef.current) {
      communityMarkerRef.current.remove();
    }
  
    // Create a popup with custom content
    const popupContent = `
      <div style="padding: 8px;">
        <div style="font-weight: bold; margin-bottom: 4px;">
          Your Community
        </div>
      </div>
    `;
  
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      offset: [0, -15], // Adjust popup position above the marker
    }).setHTML(popupContent);
  
    // Create a default black marker with the popup
    const marker = new mapboxgl.Marker({
      color: '#E26313', // Default Mapbox marker in black
    })
      .setLngLat([coordinates.longitude, coordinates.latitude])
      .setPopup(popup)
      .addTo(mapInstance);
  
    // Show popup on hover
    marker.getElement().addEventListener('mouseenter', () => popup.addTo(mapInstance));
    marker.getElement().addEventListener('mouseleave', () => popup.remove());
  
    // Store the marker reference
    communityMarkerRef.current = marker;
  }, []);
  

  return (
    <div className="clustered-listings-container">
      {/* Selection Buttons */}
      <div className="section-buttons">
        <button
          className={selectedCategory === 'sale' ? 'active' : ''}
          onClick={() => handleCategoryChange('sale')}
        >
          For Sale
        </button>
        <button
          className={selectedCategory === 'rental' ? 'active' : ''}
          onClick={() => handleCategoryChange('rental')}
        >
          Rent
        </button>
      </div>

      {/* Property Types and Beds Selection */}
      <div className="product-types-container">
        {Object.keys(propertyTypes[selectedCategory]).map((type) => (
          <div key={type} className="product-type-container">
            <button
              className={`property-type-button ${selectedPropertyType === type ? 'active' : ''}`}
              onClick={() => handlePropertyTypeChange(type)}
            >
              {type}
            </button>
            {selectedPropertyType === type && (
              <div className="product-type-buttons">
                {propertyTypes[selectedCategory][type].map((beds) => (
                  <button
                    key={beds}
                    className={selectedBeds.includes(beds) ? 'active' : ''}
                    onClick={() => handleBedsChange(beds)}
                  >
                    {beds} Bed
                  </button>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Modify the Cluster Selection section to include the toggle */}
      <h3>Select Clusters</h3>
      <div className="clustered-listings-buttons">
        {[5, 10, 15].map((clusterCount) => (
          <button
            key={clusterCount}
            onClick={() => handleClusterChange(clusterCount)}
            className={`cluster-button ${selectedCluster === clusterCount ? 'active' : ''}`}
          >
            {clusterCount} Clusters
          </button>
        ))}
        
        {/* Toggle button inline with cluster buttons */}
        <button
          onClick={handleInactiveToggle}
          className="cluster-button inactive-toggle-button"
          data-showing-colors="true"
          disabled={isUpdatingMarkers}
          style={{
            padding: '10px 20px',
            backgroundColor: '#0077cc',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: isUpdatingMarkers ? 'not-allowed' : 'pointer',
            marginLeft: '20px',
            opacity: isUpdatingMarkers ? 0.7 : 1
          }}
        >
          Hide Inactive
        </button>
      </div>

      {/* Search Button */}
      <div className="search-button-container" style={{ marginTop: '20px', marginBottom: '20px' }}>
        <button
          onClick={handleSearch}
          className="search-button"
          disabled={isLoading}
          style={{
            padding: '10px 20px',
            backgroundColor: '#28a745',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: isLoading ? 'not-allowed' : 'pointer'
          }}
        >
          {isLoading ? 'Searching...' : 'Search'}
        </button>
      </div>

      {/* Optional: Display Error Message */}
      {error && (
        <div className="error-message" style={{ color: 'red', marginBottom: '20px' }}>
          {error}
        </div>
      )}

      {/* Map and Cluster Summary Table Container */}
      <div className="map-and-summary-container">
        {/* Map Section */}
        <div className="map-section">
          {/* Map container */}
          <div id="map" className="map"></div>
          
          {/* Legend moved outside map div but still inside map-section */}
          <div className="jobs-legend">
            <div className="jobs-legend-gradient"></div>
            <div className="jobs-legend-labels">
              {[1.0, 0.8, 0.6, 0.4, 0.2, 0.0].map(label => (
                <span key={label}>{label.toFixed(1)}</span>
              ))}
            </div>
          </div>
        </div>

        {/* Cluster Summary Table Section */}
        <div className="cluster-summary-section">
          <DataTable
            title="Cluster Summary"
            columns={clusterColumns}
            data={clusterData.map(row => ({
              ...row,
              isExpanded: !!expandedRows[row.cluster]
            }))}
            expandableRows
            expandableRowsComponent={({ data }) => <ExpandedRow data={data} />}
            expandableRowExpanded={row => !!expandedRows[row.cluster]}
            onRowExpandToggled={(expanded, row) => handleRowExpand(row)}
            pagination
            noDataComponent="No listings for this filter"
            customStyles={{
              rows: {
                style: {
                  borderBottom: '1px solid #e0e0e0',
                },
              },
              headCells: {
                style: {
                  borderBottom: '2px solid #e0e0e0',
                  fontWeight: 'bold',
                },
              },
              expanderRow: {
                style: {
                  backgroundColor: '#f8f9fa',
                },
              },
            }}
          />
        </div>
      </div>

      {/* Listings Data Table */}
      <div className="listings-container" style={{ marginTop: '20px' }}>
        <DataTable
          title="Listings Data"
          columns={columns}
          data={transformedListings}
          pagination
          noDataComponent="No listings for this filter"
          responsive
          customStyles={{
            rows: {
              style: {
                borderBottom: '1px solid #e0e0e0', // Light border for rows
              },
            },
            headCells: {
              style: {
                borderBottom: '2px solid #e0e0e0', // Thicker border for header cells
                fontWeight: 'bold',
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default ClusteredListings;

// src/firebase.js

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB3ondX-H-d1wo09YSg1EKQyt3l9AMaws4",
  authDomain: "real-torch-6b7c9.firebaseapp.com",
  projectId: "real-torch-6b7c9",
  storageBucket: "real-torch-6b7c9.appspot.com",
  messagingSenderId: "949850951163",
  appId: "1:949850951163:web:2fe5d9cf25063c0f7b4f5e",
  measurementId: "G-Q91SV660CX"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase Authentication
const auth = getAuth(firebaseApp);

// // Connect to Firebase Auth Emulator in development
// if (process.env.NODE_ENV === 'development') {
//   console.log("Connecting to Firebase Auth Emulator...");
//   connectAuthEmulator(auth, "http://localhost:9099"); // Default Firebase Auth Emulator port is 9099
// }

export { auth };

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MarketOverview from './MarketOverview';
import MarketEvaluator from './MarketEvaluator';
import ProductEvaluator from './ProductEvaluator';
import ClusteredListings from './ClusteredListings';
import MacroEconMap from './MacroEconMap';
import ZipCodeMap from './ZipCodeMap';
import './Home.css';
import api from '../api';

const Home = ({ 
  user, 
  selectedLocation, 
  selectedDistance, 
  selectedClient, 
  selectedSection, 
  onSectionChange 
}) => {
  const [locationInfo, setLocationInfo] = useState(null);

  // Fetch coordinates when selectedLocation changes
  useEffect(() => {
    const fetchLocationInfo = async () => {
      if (!selectedLocation) return;
      
      try {
        const response = await api.get('/api/get-location-details', {
          params: { locationId: selectedLocation },
        });
        console.log('Received location info:', response.data);
        setLocationInfo(response.data);
      } catch (err) {
        console.error('Error fetching location info:', err);
      } 
    };

    fetchLocationInfo();
  }, [selectedLocation]);

  return (
    <div className="home-container">
      {user ? (
        <>
          <div className="content">
            <div className="section-buttons">
              <button 
                onClick={() => onSectionChange('overview')} 
                className={selectedSection === 'overview' ? 'active' : ''}
              >
                Market Overview
              </button>
              <button 
                onClick={() => onSectionChange('market')} 
                className={selectedSection === 'market' ? 'active' : ''}
              >
                Market Evaluator
              </button>
              <button 
                onClick={() => onSectionChange('product')} 
                className={selectedSection === 'product' ? 'active' : ''}
              >
                Product Evaluator
              </button>
              <button 
                onClick={() => onSectionChange('cluster')} 
                className={selectedSection === 'cluster' ? 'active' : ''}
              >
                Listings Evaluator
              </button>
              <button 
                onClick={() => onSectionChange('macro')} 
                className={selectedSection === 'macro' ? 'active' : ''}
              >
                Macro Economic Index
              </button>
              <button 
                onClick={() => onSectionChange('zipcode')} 
                className={selectedSection === 'zipcode' ? 'active' : ''}
              >
                Zip Code Map
              </button>
            </div>

            {/* Render the selected section */}
            {selectedSection === 'overview' && (
              <MarketOverview 
                selectedLocation={selectedLocation}
                locationInfo={locationInfo}
              />
            )}
            {selectedSection === 'market' && (
              <MarketEvaluator 
                selectedLocation={selectedLocation} 
                selectedDistance={selectedDistance} 
                selectedClient={selectedClient} 
              />
            )}
            {selectedSection === 'product' && (
              <ProductEvaluator 
                selectedLocation={selectedLocation} 
                selectedDistance={selectedDistance} 
                selectedClient={selectedClient} 
              />
            )}
            {selectedSection === 'cluster' && (
              <ClusteredListings 
                selectedLocation={selectedLocation} 
                selectedDistance={selectedDistance} 
                selectedClient={selectedClient}
              />
            )}
            {selectedSection === 'macro' && locationInfo && (
              <MacroEconMap 
                selectedDistance={selectedDistance} 
                selectedLocation={selectedLocation}
                locationInfo={locationInfo} 
              />
            )}
            {(selectedSection === 'macro' || selectedSection === 'zipcode') && !locationInfo && (
              <div className="loading-message">
                <p>Loading location data...</p>
              </div>
            )}
            {selectedSection === 'zipcode' && locationInfo && (
              <ZipCodeMap 
                selectedLocation={selectedLocation}
                locationInfo={locationInfo} 
              />
            )}
          </div>
        </>
      ) : (
        <div className="home-container">
          <p>Please log in or sign up to see your application.</p>
          <div className="auth-buttons">
            <Link to="/login">
              <button className="auth-button btn btn-primary">Login</button>
            </Link>
            <Link to="/sign-up">
              <button className="auth-button btn btn-primary">Sign Up</button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;

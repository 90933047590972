import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';

const AuthCallback = ({ setUser }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const finalizeLogin = async () => {
      try {
        // Fetch user info from backend
        const response = await api.get('/@me', { withCredentials: true });

        if (response.data.id) {
          // Update global user state
          setUser({
            id: response.data.id,
            email: response.data.email,
            name: response.data.name,
            isAdmin: response.data.is_admin,
          });

          console.log('User authenticated successfully:', response.data);
          // Navigate to the home page
          navigate('/');
        } else {
          console.warn('User data missing from response. Redirecting to login.');
          navigate('/login');
        }
      } catch (error) {
        console.error('Error during authentication callback:', error);

        // Optionally show an error message or page
        navigate('/login');
      }
    };

    finalizeLogin();
  }, [navigate, setUser]);

  return (
    <div className="container mt-5 pt-5">
      <p>Processing login...</p>
    </div>
  );
};

export default AuthCallback;
